import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/service/auth/auth.service';

import { ProfileService } from 'src/service/profile/profile.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public idLoyalty: any = sessionStorage.getItem('temp_loyaltyId');
  public earnedPockets: { [key: string]: { id: string, active: number } } = {};
  public idPocket: any = sessionStorage.getItem('idPocket');
  public state: any = sessionStorage.getItem('state') || 'cash';
  public nameCust: any;
  public detailDataCustomer: any = {};
  public selectedMenu: any = sessionStorage.getItem('menu') || 'home';

  constructor(
    protected auth: AuthService,
    private authService: AuthService,
    private profileService: ProfileService,
  ) { }

  ngOnInit(): void {
    if (this.idLoyalty) {
      this.getPocketValue();
      this.getDetailCust();
    }

    if (!this.idLoyalty && location.pathname.includes('home')) {
      localStorage.clear();
      sessionStorage.clear();
    }
  }

  navChange(s: any) {

  }

  getPocketValue() {
    this.profileService.getDetailPocketActive(this.idLoyalty).subscribe({
      next: (res: any) => {
        this.earnedPockets = res.data;
        if (!this.idPocket) {
          this.idPocket = this.earnedPockets['CASH'].id;
          sessionStorage.setItem('idPocket', this.idPocket);
        }
      }
    })
  }

  getDetailCust() {
    this.profileService.getDetailCustomer(this.idLoyalty).subscribe({
      next: (res: any) => {
        this.nameCust = res.data.first_name + ' ' + res.data.last_name;
        this.detailDataCustomer = res.data;
      }
    })
  }

  logOut() {
    this.authService.logout().subscribe({
      next: (res: any) => {
        location.href = '/home';
        localStorage.clear();
        sessionStorage.clear();
      }
    })
  }

  routeToPage(s: any) {
    sessionStorage.removeItem('menu');
    if (s == 'profile') {
      sessionStorage.removeItem('state');
      sessionStorage.removeItem('tab');
      sessionStorage.removeItem('idPocket');
    }
  }

  selectMenu(item: any) {
    this.selectedMenu = item;
    sessionStorage.setItem('menu', item);
  }
}
