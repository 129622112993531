export const environment = {
  production: false,
  config: {
    authType: 'simulator',
    appId: '',
    apiUrl: 'https://api.loyalty.alpabit.com/api/',
    apiUrlVoltras: 'https://159.89.197.252:8002/api/',
    apiKey: '',
    apiSalt: '',
  }
};

