<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <div class="text-left">
                    {{year}} &copy; Alpa Loyalty.
                </div>
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    PT Alpabit Digital Inovasi
                </div>
            </div>
        </div>
    </div>
</footer>