export const API = {
    register: 'akun|v1/customer',
    login_new: 'akun|v1/customer',
    login : 'akun|v1/system',
    base : 'akun|v1/front',
    base_v2 : 'akun|v1/system',
    access : 'akses|v1/system',
    access_base : 'akses|v1/front',
    access_customer : 'akses|v1/customer',
    public_base_dummy : 'publik|v1/front/public/dummy',
    public_base : 'publik|v1/front/public',
    // list api voltras 
    list_airport : 'voltras|voltras/airport-list',
    list_country : 'voltras|voltras/station-list',
    search_hotel : 'voltras|voltras/hotel/availability',
    search_flight : 'voltras|voltras/flight/availability',
    search_train : 'voltras|voltras/train/availability',
    list_airline_all : 'voltras|voltras/airline-list/all',
    booking_ticket_train : 'voltras|voltras/train/booking',
    booking_ticket_flight : 'voltras|voltras/flight/booking',
    seat_map_train : 'voltras|voltras/train/seat-map',
    manual_seat_train : 'voltras|voltras/train/manual-seat',
    ticketing_train : 'voltras|voltras/train/ticketing',
    ticketing_flight : 'voltras|voltras/flight/ticketing'
}