<div class="account-pages pt-sm-3">
  <div class="container">
    <div class="row modal-width">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body p-3" style="padding-top: 0px !important;">
            <div class="text-center mt-2">
              <div class="text-end">
                <button type="button" class="close" data-bs-dismiss="modal" #closeModal aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <h5>Welcome!</h5>
              <p class="text-muted">Please Sign in to continue.</p>
            </div>

            <div class="p-2 mt-4">
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="mb-3">
                  <label class="form-label" for="username">Username</label>
                  <input type="text" class="form-control" id="username" formControlName="username"
                    placeholder="Enter username" />
                </div>

                <div class="mb-3">
                  <label class="form-label" for="userpassword">Password</label>
                  <input type="password" class="form-control" id="userpassword" formControlName="password"
                    placeholder="Enter password" />
                </div>

                <!-- Tampilkan reCAPTCHA hanya jika recaptchastatus === 1 -->
                <div *ngIf="recaptchastatus === '1'" class="mb-3">
                  <ngx-recaptcha2 formControlName="captcha" [siteKey]="siteKey"></ngx-recaptcha2>
                </div>

                <div class="mt-3 text-end">
                  <button [disabled]="!loginForm.valid" class="btn btn-success w-sm waves-effect waves-light"
                    type="submit">
                    Sign In <i class="fas fa-arrow-alt-circle-right font-size-12"></i>
                  </button>
                </div>
              </form>

              <!-- Tampilkan OTP form jika otpstat === 1 -->
              <div *ngIf="otpstat === 1 && showOTPForm" class="mt-4">
                <form [formGroup]="otpForm" (ngSubmit)="onOTPSubmit()">
                  <div class="mb-3">
                    <label class="form-label" for="otp">Enter OTP</label>
                    <input type="text" class="form-control" id="otp" formControlName="otp" placeholder="Enter OTP" />
                  </div>

                  <div class="mt-3 text-end">
                    <button class="btn btn-primary w-sm" type="submit">
                      Submit OTP
                    </button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>