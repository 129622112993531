import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API } from 'src/app/api-url';
import { User } from 'src/app/core/models/auth.models';

declare var require: any

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public output: any;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  login(data: any) {
    // console.log(name);
    // console.log(password);
    let body = {
      username: data.username,
      password: data.password,
      api_key: 'bTJ1MTY2MjQ1ODI2NF9BcGlLZXk=',
      signature: data.signature
    }
    return this.http.post<any>(`${API.login}/front/auth`, body)
      .pipe(map(user => {
        localStorage.setItem('currentUser', JSON.stringify(user.data.loyalty_id));
        localStorage.setItem('token_akun', user.data.token);
      }));


  }

  isLoggedIn() {
    return !!localStorage.getItem('token_acc');
  }

  getIdUser(name: any) {
    // let params = new HttpParams();
    // params = params.append('username', name);
    return this.http.get(`${API.login}/front/customer/account?username=${name}`)
  }


  logout() {
    return this.http.get(`${API.login_new}/token/revoke`);
  }

  loginCustomer(body: FormData) {
    return this.http.post<any>(`${API.login_new}/auth`, body)
      .pipe(
        map((user: any) => {
          // if (!user) {
          //   return;
          // }
          console.log(user.data.token);
          sessionStorage.setItem('temp_loyaltyId', user.data.loyalty_id);
          sessionStorage.setItem('temp_account_no', user.data.account_no);
          sessionStorage.setItem('temp_phone', user.data.phone);
          localStorage.setItem('token_acc', user.data.token);

        }),
        catchError(error => {
          return throwError(() => new Error(error.message));
        }),);
  }

  checkOTP(otp: any) {
    let body = {
      otp: otp,
      loyalty_id: sessionStorage.getItem('temp_loyaltyId'),
      email: sessionStorage.getItem('temp_email'),
    }
    return this.http.post<any>(`${API.login_new}/otp`, body)
      .pipe(
        catchError(error => {
          return throwError(() => new Error(error.message));
        }),
        map(user => {
          if (!user) {
            return;
          }
          localStorage.setItem('currentUser', JSON.stringify(user.data.loyalty_id));
          localStorage.setItem('token_akun', user.data.token);
          sessionStorage.setItem('loyaltyId', user.data.loyalty_id);
          sessionStorage.setItem('email', sessionStorage.getItem('temp_email') ?? '');
          sessionStorage.setItem('account_no', sessionStorage.getItem('temp_account_no') ?? '');
          sessionStorage.setItem('phone', sessionStorage.getItem('temp_phone') ?? '');
          sessionStorage.removeItem('temp_loyaltyId');
          sessionStorage.removeItem('temp_email');
          sessionStorage.removeItem('temp_account_no');
          sessionStorage.removeItem('temp_phone');
        }));
  }

  getConfigList() {
    return this.http.get(`${API.public_base}/config/login`);
  }


}
