import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CatalogService } from 'src/service/catalog/catalog.service';
import { VoucherComponent } from './voucher/voucher.component';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css']
})
export class CatalogComponent implements OnInit {
  public idCategory: any = null;
  public allCategory: any;
  public allPocket: any;
  public pocketSelected: any;
  public page: any = 1;
  public perPage: any = 10;
  public direction: any = 'asc';
  public sort: any = 'name';
  public totalPage: any;
  public nameProductType: any = location.pathname.split('/').pop();
  public idProductType: any;
  public params: any = new HttpParams();
  public AllProduct: any;

  public detailStatus: any = location.pathname.includes('/detail/')

  constructor(
    private catalogService: CatalogService
  ) { }

  ngOnInit(): void {
    this.getListCategory();
    this.getListPocket();
    this.catalogService.getListProductType().subscribe({
      next: (res: any) => {
        res.data.forEach((d: any) => {
          if (d.name == this.nameProductType) {
            this.getAllProduct(d.id);
          }
        })
      }
    });
    console.log(this.detailStatus);
  }

  getAllProduct(id: any) {
    this.idProductType = id;
    this.params = this.params.append('page', this.page);
    this.params = this.params.append('perPage', this.perPage);
    this.params = this.params.append('product_type', id);
    this.idCategory !== null ? this.params = this.params.append('category', this.idCategory) : null;
    this.catalogService.getProductbyProductType(this.params).subscribe({
      next: (res: any) => {
        this.AllProduct = res.data;
        this.totalPage = res.total;
      }
    })
  }

  getListCategory() {
    this.catalogService.getListCategory().subscribe({
      next: (res: any) => {
        this.allCategory = res.data;
      }
    })
  }

  getListPocket() {
    this.catalogService.getListPocket().subscribe({
      next: (res: any) => {
        this.allPocket = res.data;
        this.pocketSelected = res.data[0].name;
        sessionStorage.setItem('pocket', this.pocketSelected);
      }
    })
  }

  changePocket(p: any) {
    this.pocketSelected = p;
    sessionStorage.setItem('pocket', this.pocketSelected);
  }

  changeCategory(c: any) {
    this.idCategory = c;
    this.params = new HttpParams();
    this.getAllProduct(this.idProductType);
  }

  getPocket() {
    return this.pocketSelected;
  }

  changePage(e: any) {
    this.page = e;
    this.params = new HttpParams();
    this.getAllProduct(this.idProductType);
  }
}
