import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from 'src/app/api-url';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private http: HttpClient
  ) { }

  getDetailPocketActive(id: any) {
    let params = new HttpParams;
    params = params.append('loyalty_id', id);

    return this.http.get(`${API.base}/customer/info/${id}`, { params: params });
  }

  getDetailCustomer(id: any) {
    let formData = new FormData;
    formData.append('loyalty_id', id);

    return this.http.post(`${API.base}/customer/status`, formData);
  }

  getAddress(id: any) {
    let params = new HttpParams();
    params = params.append('loyalty_id', id);

    return this.http.get(`${API.base}/customer/address/${id}`, { params });
  }

  postAddress(formData : FormData){
    return this.http.post(`${API.base}/customer-address`, formData);
  }
}
