import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';

import { PagesRoutingModule } from './pages-routing.module';
import { HomeComponent } from './home/home.component';

import { CatalogComponent } from './catalog/catalog.component';
import { ProfileComponent } from './profile/profile.component';
// import { GamificationComponent } from './gamification/gamification.component';


@NgModule({
  declarations: [
    HomeComponent,
    CatalogComponent,
    ProfileComponent,
    // GamificationComponent
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    NgxPaginationModule
  ]
})
export class PagesModule { }
