import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/service/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

import { ProfileService } from 'src/service/profile/profile.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public idLoyalty: any = sessionStorage.getItem('temp_loyaltyId');
  public earnedPockets: { [key: string]: { id: string, active: number } } = {};
  public nameCust: any;
  public detailDataCustomer: any = {};

  constructor(
    protected auth: AuthService,
    private authService: AuthService,
    private profileService: ProfileService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.idLoyalty) {
      this.getPocketValue();
      this.getDetailCust();
    }

    if (!this.idLoyalty) {
      localStorage.clear();
      sessionStorage.clear();
    }
  }

  navChange(s: any) {

  }

  getPocketValue() {
    this.profileService.getDetailPocketActive(this.idLoyalty).subscribe({
      next: (res: any) => {
        this.earnedPockets = res.data;
      }
    })
  }

  getDetailCust() {
    this.profileService.getDetailCustomer(this.idLoyalty).subscribe({
      next: (res: any) => {
        this.nameCust = res.data.first_name + ' ' + res.data.last_name;
        this.detailDataCustomer = res.data;
      }
    })
  }

  logOut() {
    this.authService.logout().subscribe({
      next: (res: any) => {
        location.href = '/home';
        localStorage.clear();
        sessionStorage.clear();
      }
    })
  }
}
